.ReactVirtualized__Grid {
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table-header {
  background-color: lighten(#ecf0f1, 3);
  border-top: 1px solid #dee2e6;
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &__th {
    height: 100%;
    font-size: 13px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    &:first {
      border-left: none;
    }
  }
  &__cell {
    height: 100%;
    position: relative;
    &__inner {
      width: 100%;
      height: 100%;
    }
    &__icon {
      color: hsl(0, 0%, 20%);
    }
    &__name {
      color: hsl(0, 0%, 20%);
    }
    &__cell-seperator {
      position: absolute;
      top: 0px;
      right: -8px;
      width: 16px;
      height: 100%;
      // z-index: 2;
      cursor: ew-resize;
      // background-color: rgba(255,255,255, .2);// darken(#ecf0f1, 5);
      display: flex;
      flex-direction: column;
      align-items: center;
      .handle {
        display: block;
        width: 2px;
        height: 100%;
        background-color: #dee2e6;
      }
    }
  }

thead,
tbody {
  display: block;
}

tbody {
  overflow: auto;
  padding-bottom: 200px;
}

.data-cell {
  position: relative;
  &:focus-visible {
    outline: none;
  }
  &__index {
    top: 3px;
    left: 5px;
    font-size: 12px;
    color: #3498db;
  }
  .cell {
    height: 100%;
    .form-control {
      height: 100%;
    }
    &--number {
      text-align: right;
      font-family: monospace, monospace;
    }
    &--invalid {
      .form-control {
        color: #e74c3c !important;
      }
    }
  }
}

}
