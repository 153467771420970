@import '@carbon/type/scss/styles';
@import '../../../colors';

.kanban-wrapper {
  background-color: $ui-background;

  width: 100%;
  height: 100%;
  .kanban-board {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    &__header {
      background-color: red;
      &__group-by-input {
        background-color: green;
      }
      &__customize-cards {
        background-color: blue;
      }
    }
    &__columns-list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding-left: 3rem;
      padding-right: 3rem;
    }
    &__column {
      background-color: $ui-01;
      border: 1px solid $ui-03;
      min-width: 300px;
      max-width: 300px;

      box-sizing: border-box;
      padding: 1rem;
      padding-bottom: 3rem;
      margin-right: 3rem;
      margin-top: 1rem;
      margin-bottom: 3rem;
      transition: all 200ms linear;
      &--isdragging {
        cursor: grabbing;
      }
      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__title {
          color: $text-01;
          @include carbon--type-style('productive-heading-03');
          &:hover {
            cursor: grab;
          }
          &:active {
            cursor: grabbing;
          }
          &--uncategorized {
            cursor: default !important;
          }
        }
        &__collapse-toggle {
        }
      }
    }
    .kanban-card {
      background-color: $ui-02;
      border: 1px solid $ui-03;
      padding: 1rem;
      margin-top: 1rem;
      &__cover-image {
      }
      &__field {
      }
    }
  }
}
