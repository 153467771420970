* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
div[data-reactroot] {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

  .btn-no-focus:focus,.btn-no-focus:active:focus,.btn-no-focus.active:focus,
.btn-no-focus.focus,.btn-no-focus:active.focus,.btn-no-focus.active.focus {
    outline: none;
}
