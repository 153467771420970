@import '@carbon/type/scss/styles';
@import '../../../colors';

.ct-automation-panel-wrap {
    position: fixed;
    top: 48px;
    bottom: 0px;

    min-width: inherit;
    width: inherit;
    max-width: inherit;
    transform: translateX(100%);
    transition: .3s ease-out;
    &--visible {
        transform: translateX(0%);
    }
  }
  .ct-automation-panel {
    position: absolute;
    border-left: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $ui-02;
    color: $text-02;
    overflow: auto;
  }