@import '../../../colors';
@import '@carbon/type/scss/styles';

.deals-toolbar {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $ui-03;
  background-color: $ui-02;
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    transition: all 200ms ease-in;
    color: $text-02;
    font-weight: 500;
    margin-right: 1rem;
    &:hover {
      background-color: $ui-03;
      // color: $text-03;
    }
    ::after {
      content: none !important;
    }
  }
  &__popover {
    z-index: 990;
    background-color: $ui-02;
    border: 1px solid $ui-03;
    border-radius: 5px;
    padding: 0.5rem;
  }
  &__child-popover {
    flex-wrap: nowrap;
    background-color: $ui-02;
    border: 1px solid $ui-03;
    border-radius: 5px;
    padding: 0.5rem;
    margin-top: -30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    max-height: 300px;
    overflow-y: 'auto';
  }
}
.popover-up {
  display: none;
  &-enter {
    display: block;
    opacity: 0;
    margin-top: 30px;
    // transform: translateY(30px) scale(0.6);
  }
  &-enter-active {
    opacity: 1;
    transition: all 200ms ease-out;
    margin-top: 0px;
    // transform: translateY(0) scale(1);
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: all 200ms ease-in;
    margin-top: 30px;
    // transform: translateY(30px) scale(0.6);
  }
}
