@import '@carbon/type/scss/styles';
@import '../../../colors';

.automation-panel-wrap {
    position: fixed;
    top: 86px;
    bottom: 30px;
    right: 0;

    min-width: 40em;
    width: 50vw;
    max-width: 600px;
    transform: translateX(100%);
    transition: .3s ease-out;
    &--visible {
        transform: translateX(0%);
    }
  }
  .automation-panel {
    position: absolute;
    border-left: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $ui-02;
    color: $text-02;
    overflow: auto;
    padding: 1em;
  }