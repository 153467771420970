$interactive-01: #0062ff !default;
$interactive-02: #171717 !default;
$interactive-03: #0062ff !default;
$interactive-04: #0062ff !default;
$ui-background: #ffffff !default;
$ui-01: #f3f3f3 !default;
$ui-02: #ffffff !default;
$ui-03: #dcdcdc !default;
$ui-04: #8c8c8c !default;
$ui-05: #171717 !default;
$text-01: #171717 !default;
$text-02: #565656 !default;
$text-03: #8c8c8c !default;
$text-04: #ffffff !default;
$icon-01: #171717 !default;
$icon-02: #565656 !default;
$icon-03: #ffffff !default;
$link-01: #0062ff !default;
$link-02: #0043ce !default;
$field-01: #f3f3f3 !default;
$field-02: #ffffff !default;
$inverse-01: #ffffff !default;
$inverse-02: #3d3d3d !default;
$support-01: #da1e28 !default;
$support-02: #24a148 !default;
$support-03: #fdd13a !default;
$support-04: #054ada !default;
$overlay-01: rgba(23, 23, 23, 0.5) !default;
$focus: #0062ff !default;
$hover-primary: #0353e9 !default;
$active-primary: #0530ad !default;
$hover-primary-text: #054ada !default;
$hover-secondary: #4c4c4c !default;
$active-secondary: #6f6f6f !default;
$hover-tertiary: #0353e9 !default;
$active-tertiary: #0530ad !default;
$hover-ui: #e5e5e5 !default;
$active-ui: #bebebe !default;
$selected-ui: #dcdcdc !default;
$hover-selected-ui: #cacaca !default;
$hover-danger: #ba1b23 !default;
$active-danger: #750e13 !default;
$hover-row: #e5e5e5 !default;
$visited-link: #8a3ffc !default;
$disabled-01: #f3f3f3 !default;
$disabled-02: #bebebe !default;
$disabled-03: #8c8c8c !default;
$highlight: #c9deff !default;
$brand-01: #0062ff !default;
$brand-02: #171717 !default;
$brand-03: #0062ff !default;
$active-01: #bebebe !default;
$hover-field: #e5e5e5 !default;