@import '@carbon/type/scss/styles';
@import '../../../../colors';

.view-filter {
  &__current-fields-list {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      .remove-filter-field {
        cursor: pointer;
        width: 30px;
      }
      .filter-field-row-lead {
        width: 70px;
      }
      .change-filter-field {
        cursor: pointer;
        // width: 180px;
        flex-grow: 1;
      }
      .toggle-direction-filter-field {
        cursor: pointer;
        width: 160px;
      }
      .reorder-filter-field-handle {
        cursor: pointer;
        align-self: flex-end;
        flex: 1;
        justify-content: flex-end;
        align-items: flex-end;
        display: flex;
      }
    }
  }
}
.field-filter-button {
  @include carbon--type-style('productive-heading-01');
  cursor: pointer;
  border: 1px solid $ui-03;
  background-color: $ui-01;
  color: $text-01;
  padding: 3px;
  &--is-selected {
    background-color: $ui-04;
    color: $text-04;
  }
}
