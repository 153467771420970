.table-data-cell {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #dde1e3;
  border-left: 1px solid #dde1e3;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  &--is-text {
    text-align: start;
  }
  &--is-number {
    text-align: end;
  }

  
  &--is-hovered {
    background-color: lighten($color: #dde1e3, $amount: 10);
  }
  &--is-row-hovered {
    background-color: lighten($color: #dde1e3, $amount: 10);
  }
  &--is-col-hovered {
  }
  &--is-last-column {
    box-shadow: 1px 0 0 0 #dde1e3;
  }
  &--is-selected {
    background-color: #f0f7ff;
  }
  &--is-only-selected {
    background-color: white;
    box-shadow: 0 0 0 2px #2d7ff9 !important;
    z-index: 100000 !important;
  }
}
