@import '@carbon/type/scss/styles';
@import '../../../../../../colors';

.cell-popover {
  background-color: $ui-01;
  color: $text-01;
  border: 1px solid $ui-04;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  input:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &__options {
    display: flex;
    flex-direction: column;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    max-height: 200px;
    overflow: scroll;
    &__item {
      padding-left: 1rem;
      padding-right: 1rem;
      height: 30px;
      min-height: 30px;
      max-height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      transition: all 200ms linear;
      cursor: pointer;
      &--selected {
        background-color: $ui-02;
        color: $text-02;
      }
    }
  }
}
