@import '@carbon/type/scss/styles';
@import '../../../../colors';

.view-sort {
    &__current-fields-list {
        display: flex;
        flex-direction: column;
        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            margin-bottom: 1em;
            .remove-sorted-field {
                cursor: pointer;
                width: 30px;
            }
            .sorted-field-row-lead {
                width: 70px;
            }
            .change-sorted-field {
                cursor: pointer;
                width: 180px;
            }
            .toggle-direction-sorted-field {
                cursor: pointer;
                width: 160px;
            }
            .reorder-sorted-field-handle {
                cursor: pointer;
                color: $text-03;
            }
        }
    }

}
.field-sort-button {
    @include carbon--type-style('productive-heading-01');
    cursor: pointer;
    border: 1px solid $ui-03;
    background-color: $ui-01;
    color: $text-01;
    padding: 3px;
    &--is-selected {
        background-color: $ui-04;
        color: $text-04;
    }
}