.message-text {
  font-family: 'Noto Sans', sans-serif;
}

.comments-section {
  textarea:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }
}
