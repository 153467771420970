/* Popover */
.Popover-body {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  color: #000;
  background-color: white;
  border: 2px solid #dee2e6;
  border-radius: 0.3rem;
  padding: 1rem;
}
.Popover {
  z-index: 1000;
}

.Popover-tipShape {
  // fill: hsl(0, 0%, 27%);
  fill: transparent;
}
.Popover-body {
}
