.tab-content {
  height: calc(100vh - (75px)) !important;
  width: calc(100vw - (40px)) !important;
}

.tab-content > .active {
  display: inline-flex;
  height: 100%;
  width: 100%;
}

.tab-content > .active > .row {
  display: contents;
}
