.pac-container {
  background-color: #fff;
  z-index: 9999;
  position: fixed;
  display: inline-block;
  // float: left;
}
// .modal {
//   z-index: 20;
// }
// .modal-backdrop {
//   z-index: 10;
// }
