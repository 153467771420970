@import '../../../../colors';
@import '@carbon/type/scss/styles';

.fields-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    padding-right: 0.5rem;
    padding-left: 2.5rem !important;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    transition: all 200ms ease-in;
    color: $text-02;
    font-weight: 500;
    margin-right: 1rem;
    &:hover {
      background-color: $ui-03;
      // color: $text-03;
    }
  }
  .grab {
      cursor: grab !important;
  }